* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow: hidden;
}

.mask {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 300%;

    background: linear-gradient(to bottom, transparent 0%, white 50%);

    transition: all 3s ease-in-out;
   
}

.app {
    img {
        margin-top: -100vh;
        transition: all 1.5s ease-in-out;
    }

    h1 {
        font-family: 'roboto', sans-serif;
        font-size: 1.5rem;
        width: 80%;
        max-width: 800px;
        letter-spacing: 0.2rem;
        text-align: center;

        margin-top: 2rem;

        color: hsla(120, 50%, 15%, 1);


    }

    &.animate {
        .mask {
            height: 0%;
            bottom: -100vh;
        }
    
        img {
            margin:0 px;
            margin: 0px;
        }
    
    }

    a {
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid hsla(120, 50%, 15%, 1);
        color: hsla(120, 50%, 15%, 1);

        border-radius: 10px;

        margin-top: 2rem;

        &:hover {
            background-color: hsla(120, 50%, 15%, 1);
            color: white;
            box-shadow: 0px 0px 10px hsla(120, 50%, 15%, 1);
        }
    }
}